<template>
    <div class='meetinfo' >
      <item-scroll ref="ItemScroll">
        <div class='meetinfocontainer'>
          <div class="meetinfo-courseware-container" v-if="isNeedSelect">
            <div class="meetinfo-courseware-title global_primary_background_color">
              课堂讲题
            </div>
            <div class="meetinfo-courseware-button-group">
              <div class="meetinfo-courseware-select global_primary_border_color global_primary_text_color" @click="selectCourseware(0)">我帮专家选</div>
              <div class="meetinfo-courseware-select global_primary_border_color global_primary_text_color" @click="selectCourseware(1)">专家自己选</div>
            </div>
          </div>
            <div class="meetinfo-main">
                <div class='meetrowtitle global_primary_text_color'>
                    会议信息
                </div>
                <div class='meetrowinput metrowinput_first'>
                    <div class='meetrowkey'><span class='meetrowkey_mark'>*</span>会议名称</div>
                    <div class='meetclumnvalue'>
                        <div class='meet-clunmu-input global_text_input'>
                            <el-input placeholder="请输入会议名称"  v-model="meetingInfo.meeting_name" ></el-input>
                        </div>
                        <div class='inputtopblock' v-if='showBlock()' ></div>
                        <div class='inputtopblock_example' >
                                (示例：xx医院xx病例研讨会)
                        </div>
                    </div>
                </div>
                <div class='meetrowinput'>
                    <div class='meetrowkey'><span class='meetrowkey_mark'>*</span>开始日期</div>
                    <div class='meetclumnvalue global_text_input global_text_select' >
                        <el-date-picker
                            v-model="meetTime.date"
                            :editable='false'
                            :clearable='false'
                            type="date"
                            @change='changeDate'
                            value-format='YYYY/MM/DD'
                            placeholder="请选择开始日期">
                        </el-date-picker>
                        <div class='inputtopblock' v-if='showBlock()' ></div>
                    </div>
                </div>
                <div class='meetrowinput'>
                    <div class='meetrowkey'><span class='meetrowkey_mark'>*</span>开始时间</div>
                    <div class='meetclumnvalue global_text_input global_text_select' >
                        <el-select v-model="meetTime.startTime" @change='changeStartTime()'  placeholder="开始时间" style='width:100%;text-align:center;'>
                            <el-option :label="item" :value="item" v-for='(item,i) in config.dateTimes.slice(0,-2)' :key='i'></el-option>
                        </el-select>
                        <div class='inputtopblock' v-if='showBlock()' ></div>
                    </div>
                </div>
                <div class='meetrowinput'>
                    <div class='meetrowkey'><span class='meetrowkey_mark'>*</span>结束时间</div>
                    <div class='meetclumnvalue global_text_input global_text_select' >
                        <el-select v-model="meetTime.endTime"  @change='changeEndTime()'  placeholder="结束时间" style='width:100%;text-align:center;'>
                            <el-option :label="item" :value="item" v-for='(item,i) in config.dateTimes.slice(2)' :key='i'></el-option>
                        </el-select>
                        <div class='inputtopblock' v-if='showBlock()' ></div>
                    </div>
                </div>
                
                <div class='meetrowinput'>
                    <div class='meetrowkey'><span class='meetrowkey_mark'>*</span>会议讲题</div>
                    <div class='meetclumnvalue questionmeet global_text_input global_text_select'>
                        <el-select v-model="meetingInfo.courseware_id"    placeholder="会议讲题" style='width:100%;text-align:center;'>
                            <el-option :label="item.show_title" :value="item.id" v-for='(item,i) in config.courseware' :key='i' :class='{meetinfo_show_bold_text:item.id==meetingInfo.courware_id}'></el-option>
                        </el-select>
                        <div class='inputtopblock' v-if='true' ></div>
                    </div>
                </div>
                <div class='meetrowtitle global_primary_text_color'>
                    主持专家信息
                </div>
                <div class='meetrowinput'>
                    <div class='meetrowkey'><span class='meetrowkey_mark'>*</span>所在省份</div>
                    <div class='meetclumnvalue global_text_input global_text_select' >
                        <el-input placeholder="请选择省份"  v-model="doctorInfo.province"  ></el-input>
                        <div class='inputtopblock' ></div>
                    </div>
                </div>
                <div class='meetrowinput'>
                    <div class='meetrowkey'><span class='meetrowkey_mark'>*</span>所在城市</div>
                    <div class='meetclumnvalue global_text_input global_text_select' >
                        <el-input placeholder="请选择城市"  v-model="doctorInfo.city"  ></el-input>
                        <div class='inputtopblock' ></div>
                    </div>
                </div>
                <div class='meetrowinput'>
                    <div class='meetrowkey'><span class='meetrowkey_mark'>*</span>所在医院</div>
                    <div class='meetclumnvalue global_text_input global_text_select'>
                        <el-input placeholder="请选择城市"  v-model="doctorInfo.hospital"  ></el-input>    
                        <div class='inputtopblock' ></div>
                    </div>
                </div>
                <div class='meetrowinput'>
                    <div class='meetrowkey'><span class='meetrowkey_mark'>*</span>姓名</div>
                    <div class='meetclumnvalue global_text_input global_text_select'>
                        <el-input placeholder="请输入姓名"  v-model="doctorInfo.name" ></el-input>
                        <div class='inputtopblock' ></div>
                    </div>
                </div>
                

                <!-- <div class='meetrowinput otherrowinput'>
                    <div class='meetrowkey'>照片<br><span class='meetrowinputtips'>(选填)</span></div>
                    <div class='meetclumnvalue imgCont' >
                        <div  class='imgBlock'>
                            <div class='file_group'>
                                <input type='file' ref='awaterimg'  @change='changeFile' v-if='resetFile'>
                            </div>
                            <div class='cur_img' :style='{background:`url(${ meetingInfo.photo || require("@/assets/images/select_img.png")}) 0% 0% / 100% 100% no-repeat`}'  @click='selectFile()'></div>
                        </div>
                        
                        <div class='inputtopblock' v-if='showBlock()' ></div>
                    </div>
                </div>
                <div class='meetrowinput otherrowinputdesc' >
                    <div class='meetrowkey'>介绍<br><span class='meetrowinputtips'>(选填)</span></div>
                    <div class='meetclumnvalue problem_desc'>
                        <el-input
                                type="textarea"
                                :autosize="{ minRows: 5, maxRows: 5}"
                                placeholder="请输入主持专家简介..."
                                v-model.trim="meetingInfo.description">
                        </el-input >
                        <div class='can_use_num' :style='{color:meetingInfo.description.length > 500?"#f00":"#b7b7b7"}'>{{meetingInfo.description.length}}/500</div>
                        
                        <div class='inputtopblock' v-if='showBlock()' ></div>
                    </div>
                </div> -->
                <div  v-if='!meetingInfo.id'>
                    <div class='meetoperabtn'>
                        <el-button type="primary"  @click='doCommit()' round>
                            <img :src="require('@/assets/images/meetopera.png')" alt="">
                            <span>确认申请</span>
                        </el-button>
                    </div>
                </div> 
                <div class='meetalterbtn' v-if='meetingInfo.id && !showBlock()'>
                    <el-button type="primary" @click='doCommit()' round>
                        <img :src="require('@/assets/images/meetopera.png')" alt="">
                        <span>确认修改</span>
                    </el-button>
                    <span class='cancelmeet' @click='cancelMeet()'>取消会议</span>
                </div>
            </div>
        </div>
      </item-scroll>
    </div>
</template>
<script>
import ItemScroll from '@/components/unit/ItemScroll'
import upload from '@/common/uploads'
export default {
    name:'meetinfo',
    data:()=>{
        return {
            config:{
                dateTimes:[],
                courseware:[]
            },
            isloading:false,
            doctorInfo:{
              name:"",
              province:"",
              city:"",
              hospital:"",
              photo: '',
              description: '',
            },
            meetingInfo:{
                meeting_name: '',
                start_time: '',
                end_hour: '',
                courware_id: '',
                photo: '',
                description: '',
                status:0
            },
            meetTime:{
                date:'',
                startTime:'',
                endTime:''
            },
            cacheTime:{
              date:'',
              startTime:'',
              endTime:''
            },
            isNeedSelect:1,// 是否需要选择课件
            resetFile:true,
        }
    },
    
    created(){
        this.config.dateTimes = this.$tools.getDateConfig()
        this.setDocumentTitle()
        this.loadData()
        this.meetingInfo.doctor_id = this.$route.query.doctorId
        this.$nextTick(()=>{
          this.$refs.ItemScroll.initScroll()
        })
    },
    methods:{
        setDocumentTitle(){
            let query = this.$route.query;
            let title = ""
            if(this.meetingInfo.status !== 0 || query.watch) title =  "会议查看"
            else title =  query.meetId ? '会议修改' : '会议申请'
            document.getElementsByTagName('title')[0].innerHTML = title
        },
        loadData(){
          if(this.$route.query.meetId){
            this.loadMeet()
          }else{
            this.loadStoreMeetingInfo()
          }
          this.loadDcotroInfo()
          this.loadCourseware()
        },
        loadCourseware(){
          let url = this.$tools.getURL(this.$urls.admin.courseware,{id:this.$route.query.doctorId})
          this.$axios.get(url).then((res)=>{
            this.config.courseware = res.data
          })
        },
        loadStoreMeetingInfo(){
          let meetingInfo =  this.$tools.getLocalCookie("meetingInfo")
          console.log(this.meetingInfo)
          if(meetingInfo){
            this.meetingInfo= {...meetingInfo}
            this.$tools.setLocalCookie("meetingInfo","")
            this.initMeet();
          }

        },
        initMeet(){
          let date = this.meetingInfo.start_time || this.meetingInfo.end_time
          if(!date) return;
          this.meetTime.date = this.$tools.getDate(date,"yyyy-MM-dd")
          if(this.meetingInfo.start_time) this.meetTime.startTime = this.$tools.getDate(this.meetingInfo.start_time,"hh:mm")
          if(this.meetingInfo.end_time) this.meetTime.endTime = this.$tools.getDate(this.meetingInfo.end_time,"hh:mm")
          this.cacheTime = {...this.meetTime}
        },
        showBlock(){
            return this.meetingInfo.status !== 0 || this.meetingInfo.watch;
        },
        loadDcotroInfo(){
            let url = this.$tools.getURL(this.$urls.admin.doctor,{id:this.$route.query.doctorId})
            this.$axios.get(url).then((res)=>{
              this.doctorInfo = res.data
            })
        },
        changeDate(){
          if(this.$tools.getTime(this.meetTime.date) < this.$tools.getTime(this.$tools.getDate())){
            this.$tips.error({text:'会议日期不能早于今日'})
            this.meetTime.date = ''
          }
          this.cacheTime = {...this.meetTime}
        },
        loadMeet(){
            let url = this.$tools.getURL(this.$urls.admin.meeting,{meeting_id:this.$route.query.meetId})
            this.$axios.get(url).then((res)=>{
                this.meetingInfo = {...this.meetingInfo,...res.data};
                this.isNeedSelect = 0
                this.setDocumentTitle()
                this.initMeet();
                this.loadStoreMeetingInfo()
            })
        },
        selectCourseware(type){
          this.isNeedSelect = type
          if(!this.isNeedSelect){
            this.formatData()
            this.$tools.setLocalCookie("meetingInfo",this.meetingInfo)
            this.$router.push({
              path:"/coursewares",
              query:{
                type:0,
                ...this.$route.query
              },
            })
          }
        },
        formatData(){
          if(this.meetTime.startTime) this.meetingInfo.start_time = `${this.meetTime.date} ${this.meetTime.startTime}:00`
          if(this.meetTime.endTime)  this.meetingInfo.end_time = `${this.meetTime.date} ${this.meetTime.endTime}:00`
        },
        checkData(){
            if(this.meetingInfo.meeting_name == '') {
                this.$tips.error({text:'会议名称不能为空'})
                return false;
            }
            if(this.meetingInfo.start_time == '') {
                this.$tips.error({text:'会议开始时间不能为空'})
                return false;
            }
            if(!this.meetingInfo.id){
              if(this.$tools.getTime(this.meetingInfo.start_time ) < this.$tools.getTime() + 15*60*1000) {
                  this.$tips.error({text:'会议开始时间必须在当前时间15分钟之后'})
                  return false;
              }
            }
            if(this.isNeedSelect && this.meetingInfo.courseware_id == '') {
                this.$tips.error({text:'会议讲题不能为空'})
                return false;
            }
            return true;
        },
            
        doCommit(){
          this.formatData()
          if(!this.checkData() || this.isloading) return;
          this.isloading = true;
          this.meetingInfo.id ? this.updateMeet():this.createMeet()
        },
        
        updateMeet(){
            this.$axios.patch(this.$urls.admin.meeting,this.meetingInfo).then((res)=>{
                setTimeout(()=>{ this.isloading = false; },1000)
                this.$router.push({
                    path:"/doctermeet",
                    query:{
                        doctorId:this.$route.query.doctorId
                    }
                })
            }).catch(err=>{
                setTimeout(()=>{ this.isloading = false; },1000)
            })
        },
        cancelMeet(){
            if(this.isloading) return ;
            this.isloading = true;
            let url = this.$tools.getURL(this.$urls.admin.meeting,{id:this.meetingInfo.id})
            this.$axios.delete(url).then((res)=>{
                setTimeout(()=>{ this.isloading = false; },1000)
                this.$tips.success({text:'取消会议成功'});
                setTimeout(()=>{
                    this.$router.push({
                        path:"/doctermeet",
                        query:{
                            doctorId:this.$route.query.doctorId
                        }
                    })
                },1000)
            }).catch(err=>{
                setTimeout(()=>{ this.isloading = false; },1000)
            })
            
        },
        createMeet(){
            this.$axios.post(this.$urls.admin.meeting,this.meetingInfo).then((res)=>{
                setTimeout(()=>{ this.isloading = false; },1000)
                this.$router.push({
                    path:"/doctermeet",
                    query:{
                        doctorId:this.$route.query.doctorId
                    }
                })
            }).catch(err=>{
                setTimeout(()=>{ this.isloading = false; },1000)
            })
        },
        
        async changeFile(e){      
            let url =  await upload.uploadFile(e.target.files[0])
            // 更新input file
            this.resetFile = false;
            this.$nextTick(()=>{
              this.resetFile = true
              if(url) this.meetingInfo.photo = url;
            })
        },
        selectFile(){
            this.$refs.awaterimg.click();
        },
        changeStartTime(){
          if(!this.meetTime.date){
            this.$tips.error({text:'请先选择开始日期'})
            this.meetTime.startTime = ""
            return;
          }
          let startTime = this.$tools.getTime(`${this.meetTime.date} ${this.meetTime.startTime}:00`)
          if(startTime < Date.now() + 15*60*1000 ){
            this.$tips.error({text:'会议开始时间必须在当前时间15分钟之后'})
            this.meetTime = {...this.cacheTime}
            return
          }
          if(!this.meetTime.endTime) {
            this.meetTime.endTime = this.$tools.getDate(startTime + 30*60*1000,"hh:mm")
          }else{
            let endTime = this.$tools.getTime(`${this.meetTime.date} ${this.meetTime.endTime}:00`)
            if(startTime + 30*60*1000 > endTime){            
              this.meetTime.endTime = this.$tools.getDate(startTime + 30*60*1000,"hh:mm")
              this.cacheTime = {...this.meetTime}
            }
          }

        },
        changeEndTime(){
          if(!this.meetTime.date){
            this.$tips.error({text:'请先选择开始日期'})
            this.meetTime.startTime = ""
            return;
          }
          let endTime = this.$tools.getTime(`${this.meetTime.date} ${this.meetTime.endTime}:00`)
          if(!this.meetTime.startTime) {
            this.meetTime.endTime = this.$tools.getDate(startTime - 30*60*1000,"hh:mm")
            this.cacheTime = {...this.meetTime}
          }else{
            let startTime = this.$tools.getTime(`${this.meetTime.date} ${this.meetTime.startTime}:00`)
            if(startTime + 30*60*1000 > endTime){            
              this.$tips.error({text:'会议结束时间必须在开始时间30分钟之后'})
              this.meetTime = {...this.cacheTime}
            }
          }
        }
    },
    components:{
      ItemScroll
    }
}
</script>
<style>

.meetinfo{
    padding: 2.4vw 4vw 0;
    height:100%;
    overflow: hidden;
    position: relative;
    background:#f2f2f2
}

.meetinfo-main{
  background:#fff;
  padding:0 2vw;
}
.meetinfo-courseware-container{
  /* height:20vw; */
  background:#fff;
  padding:4vw 2vw;
  margin-bottom:4vw;
}
.meetinfo-courseware-button-group{
  display: flex;
  justify-content: center;
}
.meetinfo-courseware-select{
  padding:2vw 4vw;
  border-width:1px;
  border-style: solid;
  border-radius:999vw;
  margin-top:4vw;
}
.meetinfo-courseware-select:first-child{
  margin-right:4vw;
}
.meetinfo-courseware-title{
  color:#fff;
  width:24vw;
  text-align:center;
  border-radius:999vw;
  padding:1vw 0;
}
.meetinfo .el-date-editor .el-input__prefix{
    display:none;
    width:0;
}
.meetinfo .el-date-editor .el-input__inner{
    padding-left:0;
}
.time-select-item{
    font-size:3vw !important;
    font-weight:400;
    line-height:4.8vw !important;
} 
.meetinfo .meetinfocontainer{
    height:100%;
    overflow: hidden;
}
.meetinfo .el-input.is-disabled .el-input__inner{
    color:#000 !important;
}
/* .el-select-dropdown  ul.el-scrollbar__view.el-select-dropdown__list span {
    font-size: 3.6vw;
} */
.meetinfo .questionmeet input{
    font-size: 3.6vw;
}
.meetinfo .meetrowinput {
  clear:both;
  width:100%;
  display: flex;
  align-items: center;
  padding:2vw 0;
  font-weight: 400;
  position: relative;
}



.meetinfo .otherrowinput{
    align-items: flex-start;
}
.meetinfo .otherrowinputdesc{
    align-items: flex-start;
    border-bottom:1px solid #E6E6E6;
    height:40vw;
}
.topic input{
    text-align: center !important;
}
.meetinfo .meetrowkey{
    min-width:22vw;
    height:100%;
    float:left;
    text-align: left;
    font-size: 4vw;
    color:#7e7e7e;
}

.meetinfo .meetrowkey>span.meetrowkey_mark{
    color:#f00;
}
.meetinfo .meetclumnvalue{
  border-bottom:1px solid #e2e2e2;
  float:left;
  flex: 1;
  position: relative;
  /* max-width:67vw; */
}
.meetinfo .meetclumnvalue .el-select{ 
  /* max-width:67vw; */
}
.meetinfo .metrowinput_first {
    padding-bottom:4vw !important;
    position: relative;
}
.meetinfo .metrowinput_first .meetclumnvalue {
    border:none !important;
    /* padding-bottom: 1vw; */
}
.meetinfo .metrowinput_first .meet-clunmu-input {
    /* padding-bottom: 1vw; */
}
.meetinfo .inputtopblock_example{
  border-bottom:1px solid #e2e2e2;
  width:100%;
  display: flex;
  position: absolute;
  top:100%;
  right:0;
  font-size:3.2vw;
  padding-bottom:2vw;
  color:#c0c4cf
}
.meet-clunmu-input{
    height:100%;
    width:100%;
    position: relative;
    padding:2vw 0
}
.meet-clunmu-input input{
    height:5.6vw;
    line-height: 5.6vw;
    padding:0;
    margin:0;
}
.meetinfo .inputtopblock{
    width:100%;
    height:100%;
    position: absolute;
    top:0;
}
/* .el-select-dropdown{
  max-width:67vw;
} */
.meetinfo  .meetrowtitle{
    height:12vw;
    line-height: 12vw;
    font-size:4vw;
    font-weight: 400;
    border-bottom:1px solid #E6E6E6;
}
.meetinfo  input{
  border: none !important;
  color:#000;
  padding:0;
  font-size:4vw;
}
.meetinfo  .is-disabled input{
    background:none !important;;
}
.meetinfo .select{
    position: absolute;
    width: 100%;
    background: #ececec;
    height: 8vw;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    opacity: 0;
}
.meetinfo .selectTop{
    position: absolute;
    width: 100%;
    background: #ececec;
    opacity: 0;
    top:0;
    left:0;
    height:100%;
}
.meetinfo .select>i{
    font-size: 8vw;
    color: #81304B;
}
.meetinfo .showDate{
  position: absolute;
  top:0;;
  left:0;
  z-index:100;
  width:100%;
  height:100%;
}
.meetinfo .show_dateBg{
  /* height:954px; */
  position: absolute;
  top:0;
  left:0;
  height:100%;
  width:100%;
  opacity:0;
}
.meetinfo .date_main{
  width:100%;
  position: absolute;
  bottom:0;
  left:0;
}
.meetinfo .problem_desc{
    position: relative;
    border:none;
}
.meetinfo  .el-textarea{
    margin:0;
    padding:0;
    font-size:4vw;
    flex:1;
}
.meetinfo .el-textarea__inner:focus{
    border-color:#C0C4CC !important;
}
.meetinfo .el-textarea__inner{
    height:40vw;
    border:0 !important;
    padding:0;
    overflow-y: auto;
  /* font-size: 30px; */
}
.meetinfo .can_use_num{
  /* position: absolute; */
  color:#b7b7b7;
  /* right:1.32vw; */
  /* bottom:.8vw; */
  /* flex:16vw 0 0; */
  font-size:4vw;
  display: inline-block;
  width:100%;
  font-weight: 100;
  text-align: right;
}
.meetinfo .file_group{
    display: none;
}
.meetinfo .imgCont{
    height:24vw;
    display: flex;
    padding-bottom:4vw;
}
.meetinfo .imgBlock{
    margin-right:5vw;
    width:18vw;
    height:100%;
    border:1px solid gray;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
}
.meetinfo .imgBlock:last-child{
    margin:none
}

.meetinfo .cur_img{
    height:100%;
    width:100%;
}
.meetinfo .meetrelated{
    height:17.73vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    border-bottom:1px solid #E6E6E6;
}
.meetinfo .meetrelatedname{
    color:#000;
    /* font-size:3.86vw; */
    font-size:3vw;
    line-height:6.26vw;
}
.meetinfo .meetrelatedocco{
    color:#666;
    font-size:3.33vw;
    line-height:6.26vw;
    font-weight: 400
}
.meetinfo .meetspaceline {
    display:flex;
}
.meetinfo .meetspaceline  span{
    display: inline-block;
    width:6.67vw;
    height:1px;
    background:#ccc;
}
.meetinfo .meetshowtips{
    margin-top:6.66vw;
    font-size:3.33vw;
    width:100%;
    text-align: center;
    color:#bbb;
}
.meetinfo .meetoperabtn{
    height:24.33vw;
    padding:4.4vw 5.86vw;
}

.meetinfo .meetoperabtn>button{
    width:100%;
    font-size:3.73vw;
}
.meetinfo .meetoperabtn>button>span{
    display: inline-block;
}
.meetinfo .meetoperabtn>button span,.meetinfo .meetoperabtn>button img{
    vertical-align: middle;
}
.meetinfo .meetalterbtn{
    height:22.26vw;
    width:100%;
    padding:6.53vw 0;
    display:flex;
    align-items: center;
}
.meetinfo .meetalterbtn>button{
    flex:1;
    /* height:9.33vw; */
    width:100%;
    font-size:3.73vw;
    margin-right:11.4vw;
}
.meetinfo .meetalterbtn>button>span{
    display: inline-block;
}
.meetinfo .meetalterbtn>button span,.meetinfo .meetalterbtn>button img{
    vertical-align: middle;
}
.meetinfo .cancelmeet{
    font-size:3.73vw;
    color:#666;
    /* width:15.6vw; */
    width:17.6vw;
}
.meetinfo .meetusericon{
    position: absolute;
    right: 2.64vw;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    color: #e6e6e6;
}
.meetinfo .showtimeblock{
    width: 48vw;
    height: 100%;
    background: #fff;
    position: absolute;
    left: 0;
    top:0;
    font-weight: 400;
    display: flex;
    align-items: center;
    /* border-bottom:1px solid #ececec */
}
.meetrowinputtips{
  color:#c0c4cf;
  display: inline-block;
  margin-top:.5vw;
}

.meetinfo_show_bold_text{
  font-weight:700;
}
</style>